.el-main {
  padding: 0 20px;
}
.el-main h3 {
  margin-bottom: 20px;
}
.el-main /deep/ .sss .el-input input {
  border-color: red !important;
}
.el-main .setDsta_1 {
  width: 600px;
  margin-bottom: 20px;
}
.el-main .setDsta_1 .el-form-item {
  margin-bottom: 0;
}
.el-main .setDsta_1 .el-form-item /deep/ .el-select {
  width: calc(100% - 40px);
  float: left;
}
.el-main .setDsta_1 .el-form-item .el-button {
  margin-left: 10px;
}
.el-main .el-table {
  margin-bottom: 20px;
}
.el-main .setDsta_3 .el-form-item {
  width: 50%;
  float: left;
}
.el-main .setDsta_3 .el-form-item /deep/ textarea {
  height: 100px;
}
