








































































































































































































































.el-main {
  padding: 0 20px;

  h3 {
    margin-bottom: 20px;
  }

  /deep/ .sss {
    .el-input {
      input {
        border-color: red !important;
      }
    }
  }

  .setDsta_1 {
    width: 600px;
    margin-bottom: 20px;

    .el-form-item {
      margin-bottom: 0;

      /deep/ .el-select {
        width: calc(100% - 40px);
        float: left;
      }

      .el-button {
        margin-left: 10px;
      }
    }
  }

  .el-table {
    margin-bottom: 20px;
  }

  .setDsta_3 {
    .el-form-item {

      width: 50%;
      float: left;

      /deep/ textarea {
        height: 100px;
      }
    }
  }
}
